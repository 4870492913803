﻿/* global tumarket */

import * as $ from "jquery";

import "bootstrap/js/dist/popover";
import "bootstrap/js/dist/modal";
import tumGlobal from "../../global.js";
import { closeDialog, showDialog, showAlert } from "../../global/dialog";
import { ajaxSend } from "../../global/fetch";
import { getPrecompiledTemplate, loadPrecompiledTemplates } from "../../global/templates";
import "inputmask/dist/jquery.inputmask.js";
import { addEventDelegate, dataStorage } from "../../global/dom";

var dep = {
	options: {
		firmID: 0,
		depID: 0,
		targetBtn: {},
		ajaxURL: {
			saveRespAgents: '/firmrequests/SaveRespAgents',
			getFirmDepartment: '/firmRequests/GetFirmDepartment',
			getFirmSchedule: '/FirmRequests/GetFirmSchedule',
			saveFirmDepartment: '/firmrequests/SaveDepartmentItem',
			copyMainSchedule: '/firmrequests/CopyMainSchedule',
			getAgentContacts: "/client/agents/getagentcontacts",
			saveAgentContacts: "/client/agents/saveagentcontacts"
		},
		first_Interval: false,
		second_Interval: false,
		validCount: 0,
		isEmployee: false,
		isMainFirmAgent:false,
		saveAgentContactsCallback: null
	},
	init: function (options) {
		Object.assign(this.options, options);

		addEventDelegate(document, 'click', '.btn-group > .week-day', element => {
			$(element).toggleClass('tum-bg-yellow');
			$(element).attr('data-code', $(element).hasClass('tum-bg-yellow') ? 1 : 0);

			$('.work-time-container').toggleClass('d-none', !$('.week-day.tum-bg-yellow').length);
			this.checkSaveBtn();
		});

		addEventDelegate(document, 'click', '.btn-save-interval', element => {
			this.saveDepartmentItem($(element), element.getAttribute("data-depid"));
			let departmentsContainer = document.getElementById('departments-crud');
			if (!departmentsContainer) return;

			let isCrud = !!element.closest('.tu-crud');
			if (!isCrud) return;

			/** @type {import("../../crud.js").Crud} */
			let departmentsCrud = dataStorage.get(document.getElementById('departments-crud'), 'crud');
			departmentsCrud.refreshItem("departments", Number(element.dataset.depid));
		});

		addEventDelegate(document, 'click', '.btn-interval-add', element => {
			this.addInterval($(element).closest('.collapse-cont'), "interval", {});
		});

		addEventDelegate(document, 'click', '.btn-festive-add', element => {
			this.addInterval($(element).closest('.collapse-cont'), "calendar", {});
		});
		
		addEventDelegate(document, 'click', '.btn-interval-del', element => {
			var intervalContainer = $(element).closest('.dep-interval-container');
			this.removeInterval(intervalContainer);
		});

		$(document).on('show.bs.collapse', '#departChat, #departOrder', function(e){
			var btnIDName = $(e.target).attr('id');
			var $btn = $('.' + btnIDName);
			var departName = $btn.attr('data-departname');
			var type = $btn.attr('data-type');
			dep.getDepartmentItem($(this), {
				name: departName,
				firmID: $(this).attr('data-firmid'),
				type: type
			});
		});
		
		$(document).on('hide.bs.collapse', '#departChat, #departOrder', function(){
			$('.department-container > .dep-interval-container', $(this)).remove();
			$('.respAgentsCont', $(this)).html('');
			$('.department-container .responsible', $(this)).addClass('d-none');
			$('.department-container .schedule', $(this)).addClass('d-none');
			$('.department-container .dep-schedule-table', $(this)).html('');
			$('.department-container .actionBtn .btn-container', $(this)).remove();
		});

		addEventDelegate(document, 'click', '.dep-days-btn i', element => {
			this.setDays($(element));
		});

		$(document).on('input', '.dep-pause input', function() {
			let element = this;
			var el = $(element);
			var cont = $(el).closest('.dep-interval-container');
			var wbegTime = $('.dep-worktime .input-group input:first', cont);
			var wendTime = $('.dep-worktime .input-group input:last', cont);
			if ($(wbegTime).val() == "00:00" && $(wendTime).val() == "00:00" ||
				($(wbegTime).val() == '' && $(wendTime).val() == '')) {
				$(element).val('');
			}
		});

		addEventDelegate(document, 'click', '.dep-set-allTime', element => {
			var cont = $(element).closest('.dep-worktime');
			$(element).toggleClass('tum-bg-yellow');
			if ($(element).hasClass('tum-bg-yellow')) {
				$('.begTime', cont).val('00:00');
				$('.endTime', cont).val('23:59');
				this.disableControls($(element));
				$('.begTime', cont).removeClass('error');
				$('.endTime', cont).removeClass('error');
				$('.dep-pause-container .btn-remove-pause').each(function (i, el) {
					$(el)[0]?.click();
				});
			} else {
				$('.begTime', cont).val('');
				$('.endTime', cont).val('');
				this.disableControls($(element));
			}
			this.checkSaveBtn();
		});

		addEventDelegate(document, 'change', '.dep-agens-container .dep-types', element => {
			var container = $(element).closest('.collapse-cont');
			var selectedID = $(element).attr('data-typeid');
			var typeID = $(element).val();
			var isCheck = this.checkAgents(container);
			if (!isCheck) {
				$(element).val(selectedID);
			} else {
				$(element).attr('data-typeid', typeID)
			}
		});

		addEventDelegate(document, 'click', '.dep-add-pause', element => {
			let self = this;
			$('.btn-save-interval').prop('disabled', true);
			var errorsCount = $('.dep-worktime .error').length;
			if (errorsCount > 0) {
				return;
			}

			var w_timeBeg = this.getSecondsFromTimeStr($('.dep-worktime .begTime').val());
			var w_timeEnd = this.getSecondsFromTimeStr($('.dep-worktime .endTime').val());
			if (w_timeBeg == 0 && w_timeEnd == 0) {
				return;
			}
			
			if ($('.dep-set-allTime').hasClass('tum-bg-yellow')) {
				return;
			}

			var pauseRows = $(element).closest('.dep-pause-container').find('.dep-pause-row .dep-pause');
			var isNeedAdd = true
			$.each(pauseRows, function (i, el) {
				var p_timeBeg = self.getSecondsFromTimeStr($('.begTime', el).val());
				var p_timeEnd = self.getSecondsFromTimeStr($('.endTime', el).val());
				if ((p_timeBeg == 0 && p_timeEnd == 0) || +$(el).attr('data-valid') != 1) {
					isNeedAdd = false;
				}
			});

			if (!isNeedAdd) {
				return;
			}

			var container = $(element).closest('.dep-pause-container').find('.dep-pause-row');

			loadPrecompiledTemplates(['firm-schedule-editor-break']).then(() => {
				var tmpl = getPrecompiledTemplate('firm-schedule-editor-break');
				var htmlOut = tmpl({});
				$(container).append(htmlOut);
				$(element).closest('.dep-pause-container').find('.dep-add-pause').removeClass('empty');
				this.initTime();
				$(element).closest('.dep-pause-container').find('.dep-pause:last .begTime').focus();
			});
		});

		addEventDelegate(document, 'click', '.btn-remove-pause', element => {
			var btn = $(element);
			$(btn).closest('.dep-pause').remove();
			var rows = $('.dep-pause-row').find('.dep-pause').length;
			var nextDayRows = $('.dep-pause-row').find('.dep-pause.nextDay').length;
			if (nextDayRows == 0) {
				dep.options.second_Interval = false;
			}
			if (rows == 0) {
				$('.dep-pause-container').find('.dep-add-pause').addClass('empty');
			}
			dep.checkSaveBtn();
		});

		addEventDelegate(document, 'click', '.dep-agent-add button', element => {
			dep.addRespAgent($(element));
		});

		addEventDelegate(document, 'click', '.dep-agent-remove', element => {
			var row = $(element).closest('.agent-row');
			var agentID = +$('.agent-name', row).attr('data-agentid');
			var agentName = $('.agent-name', row).text();

			var chatOrdersContainer = $(element).closest('.collapse-cont');
			var opts = $('.dep-create-container .dep-agents option', chatOrdersContainer);
			var agentIDs = []
			$.each(opts, function (i, el) {
				agentIDs.push(Number($(el).attr('value')));
			});
			if ($.inArray(agentID, agentIDs) == -1) {
				$(".dep-create-container .dep-agents", chatOrdersContainer).append("<option value='" + agentID + "'>" + agentName + "</option>");
			}
			var container = dep.container($(element));
			$(row).remove();
			if ($('.dep-agens-container .agent-row', container).length == 0) {
				$('.resp-agent-empty', container).show();
			} else {
				$('.resp-agent-empty', container).hide();
			}


			dep.saveRespAgent(container);

		});

		addEventDelegate(document, 'change', 'select.dep-types', element => {
			var container = dep.container($(element));
			dep.saveRespAgent(container);
		});

		addEventDelegate(document, 'click', '.holiday', element => {
			if ($(element).hasClass('fa-toggle-off')) {
				$(element).removeClass('fa-toggle-off').addClass('fa-toggle-on');
			} else {
				$(element).removeClass('fa-toggle-on').addClass('fa-toggle-off');
			}
			dep.disableControls($(element));
			dep.checkSaveBtn();
		});

		$(document).on('input', '.dep-pause-row .begTime', function() {
			let element = this;
			if ($(element).closest('.input-group').find('.endTime').hasClass('error')) {
				$(element).val('');
				return;
			}
			var workBegTime = $('.dep-worktime .begTime').val() || '00:00';
			var workEndTime = $('.dep-worktime .endTime').val();
			var p_EndVal = $('.dep-pause-row .dep-pause:visible:last .endTime').val();
			var res = false;
			var isValidTime = dep.checkWorkTime($(element).val());
			if (isValidTime) {
				res = dep.isValidInterval(workBegTime, workEndTime, $(element).val(), p_EndVal, $(element).closest('.dep-pause'));
			}
			if (!res) {
				$(element).addClass('error');
				$(element).closest('.dep-pause').removeAttr('data-valid');
				$(element).closest('.dep-pause').removeClass('nextDay');
			} else {
				$(element).removeClass('error');
				$(element).closest('.dep-pause').attr('data-valid', 1);
			}
			dep.checkSaveBtn();
		});

		$(document).on('input', '.dep-pause-row .endTime', function() {
			let element = this;
			if ($(element).closest('.input-group').find('.begTime').hasClass('error')) {
				$(element).val('');
				return;
			}
			var workBegTime = $('.dep-worktime .begTime').val() || '00:00';
			var workEndTime = $('.dep-worktime .endTime').val();
			var p_BegVal = $('.dep-pause-row .dep-pause:visible:last .begTime').val();
			var res = false;
			var isValidTime = dep.checkWorkTime($(element).val());
			if (isValidTime) {
				res = dep.isValidInterval(workBegTime, workEndTime, p_BegVal, $(element).val(), $(element).closest('.dep-pause'));
			}
			if (!res) {
				$(element).addClass('error');
				$(element).closest('.dep-pause').removeAttr('data-valid');
				$(element).closest('.dep-pause').removeClass('nextDay');
			} else {
				$(element).removeClass('error');
				$(element).closest('.dep-pause').attr('data-valid', 1);
			}
			dep.checkSaveBtn();
		});

		$(document).on('input', '.dep-worktime .begTime', function() {
			let element = this;
			var isValidTime = dep.checkWorkTime($(element).val());
			if (!isValidTime || $(element).val() == '00:00') {
				$(element).addClass('error');
			} else {
				$(element).removeClass('error');
			}
			dep.checkSaveBtn();
		});

		$(document).on('input', '.dep-worktime .endTime', function() {
			let element = this;
			var isValidTime = dep.checkWorkTime($(element).val());
			if (!isValidTime || $(element).val() == '00:00') {
				$(element).addClass('error');
			} else {
				$(element).removeClass('error');
			}
			dep.checkSaveBtn();
		});

		addEventDelegate(document, 'click', '.copy-schedule-btn', element => {
			this.copyMainSchedule($(element));
		});
		
		addEventDelegate(document, 'click', '.editAgentContacts', element => {
			this.editAgentContacts($(element).closest(".agent-row").find('.agent-name').attr("data-agentid"));
		});

		addEventDelegate(document, 'click', '[data-edit-schedule]', element => {
			this.editSchedule(element.getAttribute('data-depid'), element.getAttribute('data-depname'));
		});
	},
	disableContactFields: function (isEmployee) {
		if (!isEmployee) {
			$("#asModal .user-contacts-info input").attr('disabled', true);
			$("#asModal .btn-primary").addClass("d-none");
		}
	},
	editAgentContacts: function (agentID) {
		return loadPrecompiledTemplates(['firm-contact-form']).then(() => {
			return ajaxSend({ url: dep.options.ajaxURL.getAgentContacts, data: { id: agentID } }).then(data => {
				if (data.result) {
					var template = getPrecompiledTemplate('firm-contact-form');
	
					showDialog({
						title: "Контакты представителя",
						content: template(data),
						submitButtonText: "Сохранить",
						size: 'sm',
						onSubmit: () => dep.saveAgentContacts(agentID),
						onShown: () => {
							if ($('.popover.show').length) {
								$('.popover.show').remove();
							}
						}
					});
					dep.disableContactFields(dep.options.isEmployee);
				}
				else {
					showAlert("Ошибка загрузки контактов", { type: 'danger' });
				}
			});
		});
	},
	editSchedule: function(depId, depName){
		return loadPrecompiledTemplates(['firm-schedule-editor']).then(() => {
			let template = getPrecompiledTemplate('firm-schedule-editor');
			$('#schedule').remove();
			$('body').append(template({ depId, depName, firmName: tumarket.firm.name }));
			$('#schedule').modal('show');

			this.initTime();
		});
	},
	saveAgentContacts: function (agentID) {
		var params = {
			id: agentID,
			tuLogin: $(".tum-agentLoginInput").val() || "",
			familName: $(".tum-agentFamilNameInput").val() || "",
			fNameLname: $(".tum-agentFNameLNameInput").val() || "",
			email: $(".tum-agentEmailInput").val() || "",
			phone: $(".tum-agentPhoneInput").val() || "",
			additionalPhone: $(".tum-agentAdditionalPhoneInput").val() || ""
		};

		var emailregex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		var phoneRegex = /[0-9+]+/;

		if (!emailregex.test(params.email) && params.email) {
			$(".tum-agentContactsEditor-error").html("Некорректный email");
			return;
		}

		if (params.phone && (!phoneRegex.test(params.phone) ||
			!((params.phone.startsWith("+") && params.phone.length == 12) ||
				(!params.phone.startsWith("+") && params.phone.length == 11)))) {
			$(".tum-agentContactsEditor-error").html("Некорректный телефон для SMS");
			return;
		}

		if (params.additionalPhone && (!phoneRegex.test(params.additionalPhone) ||
			!((params.additionalPhone.startsWith("+") && params.additionalPhone.length == 12) ||
				(!params.additionalPhone.startsWith("+") && params.additionalPhone.length == 11)))) {
			$(".tum-agentContactsEditor-error").html("Некорректный телефон для дозвонов");
			return;
		}

		if (!params.email && !params.phone) {
			$(".tum-agentContactsEditor-error").html("Укажите телефон для SMS или email");
			return;
		}

		ajaxSend({ url: dep.options.ajaxURL.saveAgentContacts, data: params }).then(data => {
			if (data.result) {
				closeDialog();
				if (dep.options.saveAgentContactsCallback) {
					dep.options.saveAgentContactsCallback();
				}
			}
			else {
				$(".tum-agentContactsEditor-error").html("Ошибка сохранения контактов");
			}
		});
	},
	checkSaveBtn: function () {
		var isSetDay = $('.week-day[data-code=1]').length > 0;
		if (isSetDay && ($('.holiday').hasClass('fa-toggle-on') || $('.dep-set-allTime').hasClass('tum-bg-yellow'))) {
			$('.btn-save-interval').prop('disabled', false);
			return;
		}
		var w_timeBeg = dep.getSecondsFromTimeStr($('.dep-worktime .begTime').val()) || 0;
		var w_timeEnd = dep.getSecondsFromTimeStr($('.dep-worktime .endTime').val()) || 0;
		var isSetWorkTimer = (w_timeBeg != 0 && w_timeEnd != 0) || $('.holiday').hasClass('fa-toggle-on');
		var isCorrectWorkTimer = !($('.dep-worktime .begTime').hasClass('error') && !$('.dep-worktime .endTime').hasClass('error')) || $('.holiday').hasClass('fa-toggle-on');

		if (isSetDay && isSetWorkTimer && isCorrectWorkTimer) {
			if ($('.dep-pause').length > 0) {
				var p_timeBeg = dep.getSecondsFromTimeStr($('.dep-pause:last .begTime').val()) || 0;
				var p_timeEnd = dep.getSecondsFromTimeStr($('.dep-pause:last .endTime').val()) || 0;
				var isSetPauseTime = false;
				if (p_timeBeg != 0 && p_timeEnd != 0) {
					isSetPauseTime = true;
				}
				var isCorrectPauseTime = false;
				if (!$('.dep-pause:last .begTime').hasClass('error') && !$('.dep-pause:last .endTime').hasClass('error')) {
					isCorrectPauseTime = true;
				}

				$('.btn-save-interval').prop('disabled', !(isSetPauseTime && isCorrectPauseTime));
			} else {
				$('.btn-save-interval').prop('disabled', false);
			}
		} else {
			$('.btn-save-interval').prop('disabled', true);
		}
	},
	checkWorkTime: function (time) {
		if (time == '24:00') {
			time = '23:59';
		}
		var res = true;
		var seconds = dep.getSecondsFromTimeStr(time);
		var maxSeconds = dep.getSecondsFromTimeStr('23:59');
		if (!time.match(/^[0-9]+:[0-9]+$/)) {
			res = false;
		}
		if (seconds > maxSeconds) {
			res = false;
		}
		return res;
	},
	disableControls: function (el) {
		var isHoliday = $(el).hasClass('holiday');
		var isChecked = false;
		var cont = {};
		if (!isHoliday) {
			isChecked = $(el).hasClass('tum-bg-yellow');
			cont = $(el).closest('.dep-worktime');
		} else {
			isChecked = $(el).hasClass('fa-toggle-on');
			cont = $(el).closest('.modal-body').find('.dep-worktime');
			$('.dep-pause-container .btn-remove-pause', $(el).closest('.modal-body')).each(function (i, el) {
				$(el)[0]?.click();
			});
			isChecked ? $('.pause-block').addClass('d-none') : $('.pause-block').removeClass('d-none')
		}
		isChecked ? $('.dep-pause-name', cont).addClass('no-active') : $('.dep-pause-name', cont).removeClass('no-active');
		isChecked ? $('button', cont).addClass('disabled no-active') : $('button', cont).removeClass('disabled no-active');
		//isChecked ? $('input', cont).prop('disabled', true).addClass('no-active') : $('input', cont).prop('disabled', 'false').removeClass('no-active');
	},
	initTime: function () {
		$('.endTime, .begTime').inputmask("99:99");
	},
	copyMainSchedule: function (el) {
		var container = dep.container(el);
		var depID = $(container).attr('data-depid');
		var params = { firmID: dep.options.firmID, depID: depID }
		ajaxSend({ url: dep.options.ajaxURL.copyMainSchedule, data: params }).then(data => {
			if (data.result) {
				dep.getSchedule(container);
			} else {
				showAlert(data.msg || "Возникла ошибка.", { type: 'danger' });
			}
		});
	},
	addRespAgent: function (el) {
		var chatOrdersContainer = dep.container(el);
		var addContainer = $(el).closest('.dep-create-container');

		var agentID = $('.dep-agents', addContainer).val();
		var agentName = $(".dep-agents option[value=" + agentID + "]", addContainer).text();
		if (agentID == 0) {
			return;
		}
		var typeID = $('.dep-types', addContainer).val();
		var typeName = $(".dep-types option[value=" + typeID + "]", addContainer).text();

		var isCheck = dep.checkAgents(chatOrdersContainer, typeName.toLowerCase(), agentID);
		if (!isCheck) return;
		var types = [];
		$.each($('.dep-types option', addContainer), function (i, el) {
			types.push({ id: $(el).attr('value'), name: $(el).text() });
		});

		var params = { agentId: agentID, agentName: agentName, typeID: typeID, types: types, agentPhone: '1', agentEmail: '1', agentPhoneAdditional: '1' };

		loadPrecompiledTemplates(['firm-agent']).then(() => {
			var tmpl = getPrecompiledTemplate('firm-agent');
			var htmlOut = tmpl(params);
			$('.dep-agens-container', chatOrdersContainer).append(htmlOut);
			dep.saveRespAgent(chatOrdersContainer, params, true);
		});
	},
	saveRespAgent: function (container, params, isAdd) {
		var agentsData = dep.getFormDataAgents(container);
		if (agentsData.agentTypes.length > 0 && !dep.checkResponsibleAgent(agentsData.agentTypes)) {
			showAlert("Необходимо указать одного ответственного или контроллера", { type: 'danger' });
			return;
		}
		var p = { firmID: dep.options.firmID, type: agentsData.type, agentTypes: agentsData.agentTypes }
		ajaxSend({ url: dep.options.ajaxURL.saveRespAgents, data: p }).then(data => {
			if (data.result) {
				if (isAdd) {
					$(".dep-agents option[value=" + params.agentId + "]", container).remove();
					var icons = this.showAbsentContactIcon(data);
					$('.dep-agens-container .agent-row:last .resp-contacts-icon', container).html(icons);
					$('.resp-agent-empty', container).hide();
				}
			} else {
				if (isAdd) {
					$('.dep-agens-container .agent-row:last', container).remove();
				}
				showAlert(data.mdg || "Возникла ошибка.", { type: 'danger' });
			}
		});
	},
	container: function (el) {
		return $(el).closest('.collapse-cont');
	},
	getSchedule: function (container) {
		var firmID = $(container).attr('data-firmid');
		var depId = $(container).attr('data-depid');
		var depName = $(container).attr('data-depname');
		var params = { firmID: firmID, depID: depId };

		loadPrecompiledTemplates(['firm-schedule', 'firm-schedule-editor-button']).then(() => {
			ajaxSend({ url: dep.options.ajaxURL.getFirmSchedule, data: params }).then(data => {
				if (data.result) {
					var scheduleTemplate = getPrecompiledTemplate('firm-schedule');
					let editButtonTemplate = getPrecompiledTemplate('firm-schedule-editor-button');
					let editButtonHtml = "";
					if (!data.Schedules_noExceptDate?.length) {
						editButtonHtml = editButtonTemplate({ 
							name: 'создать', 
							title: 'создание режима работы:', 
							isSchedule: $('.tum-firm-schedule-cont').length > 0,
							depId,
							depName
						});
						$('.dep-schedule-empty', container).removeClass('d-none');
					} else {
						editButtonHtml = editButtonTemplate({ 
							name: 'изменить', 
							title: 'изменение режима работы:',
							depId,
							depName
						});
						var scheduleModel = Object.assign({}, data, { localTime: tumGlobal.getFullDate() });
						let scheduleHtml = scheduleTemplate(scheduleModel);
						$('.dep-schedule-table', container).html($(scheduleHtml));
						$('.dep-schedule-empty', container).addClass('d-none');
					}
	
					$('.actionBtn .btn-container', container).remove();
					$('.actionBtn', container).append(editButtonHtml);

					if ($(".departOrder").hasClass("editFirmSchedule")) {
						this.editSchedule(depId, depName);
					}
				} else {
					showAlert("Возникла ошибка.", { type: 'danger' });
				}
			});
		});
	},
	getFormDataAgents: function (container) {
		var res = {};
		var btnIDName = $(container).attr('id');
		var type = $('.' + btnIDName).attr('data-type');
		res["type"] = type;
		container = $(container).find('.dep-agens-container');
		var rows = $('.agent-row', container);
		var agents = [];
		$.each(rows, function (i, el) {
			var agentID = $('.agent-name', el).attr('data-agentid');
			var typeID = $('.dep-types', el).val();
			agents.push({ firmAgentID: agentID, firmTypeID: typeID });
		});

		res["agentTypes"] = agents;
		return res;
	},
	setDays: function (btn) {
		var days = $(btn).attr('data-days').split('').map(Number);
		var dayFrom = days[0], dayTo = days[1];
		var btns = $('.week-day', btn.closest('.dep-interval-container'));
		for (var i = 1; i <= btns.length; i++) {
			var item = $(btns[i - 1]);
			if (i >= dayFrom && i <= dayTo) {
				if (!$(item).hasClass('tum-bg-yellow')) {
					$(item).toggleClass('tum-bg-yellow');
				}
				if ($(item).hasClass('tum-bg-yellow')) {
					$(item).attr('data-code', 1);
				} else {
					$(item).attr('data-code', 0);
				}
			} else {
				$(item).removeClass('tum-bg-yellow');
				$(item).attr('data-code', 0);
			}
		}
		$('.work-time-container').removeClass('d-none');
		dep.checkSaveBtn();
	},
	checkResponsibleAgent: function (agents) {
		var res = false;
		var mainAgentsIDs = [3898, 3897];
		$.each(agents, function (i, el) {
			if ($.inArray(Number(el.firmTypeID), mainAgentsIDs) !== -1) {
				res = true;
			}
		});
		return res;
	},
	checkAgents: function (container, typeName, currentAgentID) {
		var res = true;
		var rows = $('.dep-agens-container .agent-row', container);
		var controller = typeName == "контроллер" ? 1 : 0; //контроллер
		var responsible = typeName == "ответственный" ? 1 : 0; //ответственный
		if ($(rows).length == 0) { return true; }
		var rowAgentIDs = [];
		$.each(rows, function (i, el) {
			rowAgentIDs.push(+$('.agent-name', el).attr('data-agentid'));
		})
		$.each(rows, function (i, el) {
			var typeID = $('.dep-types', el).val();
			var typeName = $(".dep-types option[value=" + typeID + "]", el).text();

			if (typeName.toLowerCase() == "ответственный") {
				responsible++;
			}
			if (typeName.toLowerCase() == "контроллер") {
				controller++;
			}
		});
		if (controller > 1) {
			showAlert('Тип "контроллер" может быть только один, он уже есть в списке', { type: 'danger' });
			return false;
		}
		if (responsible > 1) {
			showAlert('Тип "ответственный" может быть только один, он уже есть в списке.', { type: 'danger' });
			return false;
		}
		if (responsible == 0 && controller == 0) {
			showAlert("Сначала добавьте ответственного или контроллера", { type: 'danger' });
			return false;
		}
		if ($.inArray(+currentAgentID, rowAgentIDs) !== -1) {
			showAlert('Такой сотрудник с типом "помощник" уже есть в списке', { type: 'danger' });
			return false;
		}
		return res;
	},
	getDepartmentItem: function (container, params) {
		//обработка чатов, обработка заказов
		loadPrecompiledTemplates(['firm-responsible-agents']).then(() => {
			ajaxSend({ url: this.options.ajaxURL.getFirmDepartment, data: params }).then(data => {
				$(container).attr('data-depid', data.DepID);
	
				var responsibleAgentsTemplate = getPrecompiledTemplate('firm-responsible-agents');
				var responsibleAgentsHtml = responsibleAgentsTemplate({ agents: data.RespAgents.otherAgents, types: data.RespAgents.types, currentAgents: data.RespAgents.currentAgents });
				$('.respAgentsCont', container).append(responsibleAgentsHtml);
				$('.respAgentsCont .resp-agent-empty', container).toggleClass('d-none', !data.RespAgents.currentAgents.length)
	
				var s = '';
				var agentCount = data.RespAgents.otherAgents.length;
				var linkAddAgents = this.options.isEmployee ? "&nbsp;<a href='/admin/agents?firmid=" + params.firmID + "' target='_blank'><i class='fas fa-external-link-alt tum-blue'></i></a>" : "";

				if (this.options.isMainFirmAgent) {
					linkAddAgents = "&nbsp;<a href='/client/agents?firmid=" + params.firmID + "' target='_blank'><i class='fas fa-external-link-alt tum-blue'></i></a>";
				}

				if (agentCount > 0) {
					s = '<span class="responsible-count">У организации зарегистрирован' +
						(agentCount == 1 ? ' ' : 'о ') + agentCount + ' представител' + (agentCount == 1 ? 'ь' : agentCount < 5 ? 'я' : 'ей') + linkAddAgents + '</span>';
				} else {
					s = '<span class="responsible-count">У организации еще нет зарегистрированных представителей ' + linkAddAgents + '</span>';
				}
				$('.department-container .agents-info-text', container).html(s);
				$('.department-container .agents-container', container).removeClass('d-none');
				this.getSchedule(container);
				$('[data-toggle="popover"]').popover({ trigger: "hover", placement: 'top', html: false });
			});
		});
	},
	isNextDay: function (begTime, endTime) {
		var res = false;
		var b_seconds = dep.getSecondsFromTimeStr(begTime);
		var e_seconds = dep.getSecondsFromTimeStr(endTime);
		if (b_seconds > e_seconds) {
			res = true;
		}
		return res;
	},
	getTimes: function (begTime, endTime, isInterval) {
		var b_seconds1 = 0, b_seconds2 = 0, e_seconds1 = 0, e_seconds2 = 0;
		if (isInterval) {
			b_seconds1 = dep.getSecondsFromTimeStr(begTime);
			e_seconds1 = dep.getSecondsFromTimeStr(endTime);
			b_seconds2 = dep.getSecondsFromTimeStr('00:00');
			e_seconds2 = dep.getSecondsFromTimeStr('00:00');
		} else {
			b_seconds1 = dep.getSecondsFromTimeStr(begTime);
			e_seconds1 = dep.getSecondsFromTimeStr('23:59');
			b_seconds2 = dep.getSecondsFromTimeStr('00:00');
			e_seconds2 = dep.getSecondsFromTimeStr(endTime);
		}
		var time1 = { 'begin': b_seconds1, 'end': e_seconds1 };
		var time2 = { 'begin': b_seconds2, 'end': e_seconds2 };
		return { time1: time1, time2: time2 }
	},
	checkPauseForOneDay: function (rows, times, i_bTime, i_eTime, workEndtime_firstInterval, isNextDay) {
		var res = false;
		if (rows > 1) {
			//если rows больше 1, то сравниваем следующий перерыв с предыдущим
			times = dep.getTimes($('.dep-pause-row .dep-pause:visible:last .begTime').val(), $('.dep-pause-row .dep-pause:visible:last .endTime').val());
			//извлекаем предыдущий блок с перерывом. Добавили блок, их стало 2. значит пред. будет rows-2 = 0 и т.д.
			var prevPause = $('.dep-pause-row .dep-pause:visible')[rows - 2];
			var prev_begTime = dep.getSecondsFromTimeStr($('.begTime', prevPause).val());
			var prev_endTime = dep.getSecondsFromTimeStr($('.endTime', prevPause).val());
			if (i_bTime < prev_begTime || i_bTime < prev_endTime) {
				return false;
			} else {
				res = true;
			}
			if (i_bTime == '' && i_eTime > 0 ||
				(i_eTime != "" && i_eTime < i_bTime) ||
				(i_eTime != "" && i_eTime < prev_endTime) ||
				(i_eTime != "" && i_eTime < prev_begTime) ||
				(i_eTime != "" && i_eTime > workEndtime_firstInterval)) {
				return false;
			} else {
				res = true;
			}
			if (!res) {
				return false;
			}
		} else {
			//если время начала перерыва больше времени конца перерыва - ошибка!!!
			if (i_eTime != "" && i_bTime > i_eTime) {
				return false;
			}
			//условие для валидации перерывов в рамках нескольких дней иначе для одного
			if (isNextDay) {
				//начало время перерыва больше начала времени рабочего дня или время начала перерыва равно 0 и конечное время перерыва меньше 23:59 рабочего времени
				if ((i_bTime > times.time1.begin || i_bTime == 0) && i_bTime < times.time1.end) {
					res = true;
				} else {
					return false;
				}
				if (i_eTime != "" && (i_eTime > times.time1.begin && i_eTime < times.time1.end)) {
					res = true;
				} else if (i_eTime == "") {
					res = true;
				} else {
					res = false;
				}
			} else {
				if ((i_bTime > times.time1.begin || i_bTime == 0) && i_bTime < times.time2.end) {
					res = true;
				} else {
					return false;
				}
				if (i_eTime != "" && (i_eTime > times.time2.begin && i_eTime < times.time2.end)) {
					res = true;
				} else if (i_eTime == "") {
					res = true;
				} else {
					return false;
				}
			}

		}
		return res;
	},
	isValidInterval: function (begTime, endTime, inputBegTime, inputEndTime, pauseRow) {

		var res = false;
		var rows = $('.dep-pause-row .dep-pause:visible').length;
		var times = dep.getTimes(begTime, endTime);
		var i_bTime = inputBegTime != '' ? dep.getSecondsFromTimeStr(inputBegTime) : '';
		var i_eTime = inputEndTime != '' ? dep.getSecondsFromTimeStr(inputEndTime) : '';
		//если рабочее время по нулям, то блок перерыва не проходит валидацию
		if (dep.getSecondsFromTimeStr(begTime) == 0 && dep.getSecondsFromTimeStr(endTime) == 0) {
			return false;
		}
		if (i_bTime > dep.getSecondsFromTimeStr("23:59") ||
			i_eTime > dep.getSecondsFromTimeStr("23:59")) {
			return false;
		}
		//workEndtime_firstIntervalOneDay для проверки валидации для одного дня. Берем time2 по второму интервалу. Первый от начала до 23:59
		var workEndtime_firstIntervalOneDay = times.time2.end;
		var workEndtime_secondInterval = times.time2.end;
		if (dep.isNextDay(begTime, endTime)) {
			//workEndtime_firstIntervalNextDay для проверки валидации для следующего дня
			var workEndtime_firstIntervalNextDay = times.time1.end;
			// время начала перерыва больше времени начала рабочего дня при условии, что делаем проверки в рамках одного дня
			if (i_bTime > times.time1.begin && !dep.options.second_Interval) {
				dep.options.second_Interval = false;
				dep.options.fist_Interval = true;
			}
			// время начала перерыва меньше времени начала рабочего дня, делаем проверки валидации уже в рамках второго дня
			if (i_bTime < times.time1.begin) {
				dep.options.second_Interval = true;
				//dep.options.fist_Interval = false;
			}
			//делаем проверки относительно первого интервала
			if (dep.options.fist_Interval && !dep.options.second_Interval) {
				res = dep.checkPauseForOneDay(rows, times, i_bTime, i_eTime, workEndtime_firstIntervalNextDay, true);
			}
			//делаем проверки относительно второго интервала
			if (dep.options.second_Interval) {
				$(pauseRow).addClass('nextDay');
				let rows = $('.dep-pause-row .dep-pause.nextDay:visible').length;
				if (rows > 1) {
					times = dep.getTimes($('.dep-pause-row .dep-pause.nextDay:visible:last .begTime').val(), $('.dep-pause-row .dep-pause.nextDay:visible:last .endTime').val(), true);
					var prevPause = $('.dep-pause-row .dep-pause.nextDay:visible')[rows - 2];
					var prev_begTime = dep.getSecondsFromTimeStr($('.begTime', prevPause).val());
					var prev_endTime = dep.getSecondsFromTimeStr($('.endTime', prevPause).val());
					if (i_bTime < prev_begTime || i_bTime < prev_endTime) {
						return false;
					} else {
						res = true;
					}
					if (i_bTime > prev_endTime && i_bTime > workEndtime_secondInterval) {//если больше конца времени первого интервала
						return false;
					}
					if (i_bTime == '' && i_eTime > 0 ||
						(i_eTime != "" && i_eTime < i_bTime) ||
						(i_eTime != "" && i_eTime < prev_endTime) ||
						(i_eTime != "" && i_eTime < prev_begTime) ||
						(i_eTime != "" && i_eTime > workEndtime_secondInterval)) {
						return false;
					} else {
						res = true;
					}
					if (!res) {
						return false;
					}
				} else {
					if (i_eTime != "" && i_bTime > i_eTime) {
						return false;
					}
					if (i_bTime > times.time2.end) {
						return false;
					}
					if ((i_bTime > times.time2.begin || i_bTime == 0) && i_bTime < times.time2.end) {
						res = true;
					} else {
						return false;
					}
					if (i_eTime != "" && i_eTime > times.time2.end) {
						return false;
					}
				}
			}
		} else {
			//делаем обычную проверку в рамках одного дня
			res = dep.checkPauseForOneDay(rows, times, i_bTime, i_eTime, workEndtime_firstIntervalOneDay, false);
		}
		return res;
	},
	getSecondsFromTimeStr: function (time) {
		var res = '';
		var hm = time.split(':');
		if (hm.length > 1) {
			res = (+hm[0]) * 60 * 60 + ((+hm[1]) * 60);
		}
		return res;
	},
	getFormData: function (el, depID) {
		var container = $(el).closest('#schedule').find('.modal-body');
		var btns = $('.dep-interval-container .btn-group .week-day[data-code="1"]', container);
		var res = [];
		var rowID = 1;
		for (var i = 0; i < btns.length; i++) {
			var interval = [];
			var day = btns[i];

			var workBegTime = '', workEndTime = '', pauseBegTime = '', pauseEndTime = '';
			var pauseRows = {};
			var isHoliday = $('.holiday', container).hasClass('fa-toggle-on');
			var isAllTime = $('.dep-set-allTime', container).hasClass('tum-bg-yellow');
			if (!isHoliday) {
				workBegTime = $('.dep-worktime .begTime', container).val();
				workBegTime = workBegTime == '24:00' ? '23:59' : workBegTime;

				workEndTime = $('.dep-worktime .endTime', container).val();
				workEndTime = workEndTime == '24:00' ? '23:59' : workEndTime;

				pauseBegTime = $('.dep-pause .begTime:first', container).val() || '';
				pauseBegTime = pauseBegTime == '24:00' ? '23:59' : pauseBegTime;

				pauseEndTime = $('.dep-pause .endTime:last', container).val() || '';
				pauseEndTime = pauseEndTime == '24:00' ? '23:59' : pauseEndTime;

				pauseRows = $('.dep-pause', container);
			}
			var isNextDay = dep.isNextDay(workBegTime, workEndTime)

			var dayNumber = +$(day).attr('data-day');

			var data = {
				firmID: dep.options.firmID,
				depID: depID,
				parentID: null,
				isFullDay: isHoliday || isAllTime || (workBegTime == '' && workBegTime == ''),
				dayNumber: dayNumber,
				begTime: workBegTime,
				endTime: isNextDay ? '23:59' : workEndTime,
				begTimeStr: workBegTime,
				endTimeStr: workEndTime,
				statusID: isHoliday ? 2 : 1,
				wBreakInWorkMode: (pauseBegTime != "" || pauseEndTime != ""),
				breakInWorkNum: null,
				isDt: null,
				dt: null,
				wNextDay: isNextDay,
				intervalID: i + 1,
				rowID: rowID
			};
			interval.push(data);
			rowID += 1;
			if (data.wNextDay) {
				data = {
					firmID: dep.options.firmID,
					depID: depID,
					parentID: null,
					isFullDay: isHoliday || isAllTime || (workBegTime == '' && workBegTime == ''),
					dayNumber: dayNumber,
					begTime: '00:00',
					endTime: workEndTime,
					begTimeStr: workBegTime,
					endTimeStr: workEndTime,
					statusID: isHoliday ? 2 : 1,
					wBreakInWorkMode: (pauseBegTime != "" || pauseEndTime != ""),
					breakInWorkNum: null,
					isDt: null,
					dt: null,
					wNextDay: 0,
					intervalID: i + 1,
					rowID: rowID
				};
				interval.push(data);
				rowID += 1;
			}
			if (pauseBegTime != "" || pauseEndTime != "") {
				for (var j = 0; j < pauseRows.length; j++) {
					var bTime = $('.begTime', pauseRows[j]).val()
					var eTime = $('.endTime', pauseRows[j]).val()
					data = {
						firmID: dep.options.firmID,
						depID: depID,
						parentID: null,
						isFullDay: false,
						dayNumber: dayNumber,
						begTime: bTime,
						endTime: eTime,
						statusID: 3,
						wBreakInWorkMode: false,
						breakInWorkNum: 1 + j,
						isDt: null,
						dt: null,
						intervalID: i + 1,
						rowID: rowID
					};
					interval.push(data);
					rowID += 1;
				}
			}
			if (interval.length > 0) {
				res.push(interval);
			}
		}
		return res;
	},
	saveDepartmentItem: function (el, depId) {
		let self = this;
		var model = this.getFormData(el, depId);
		if (model.length == 0) return;
		model = [].concat.apply([], model.map(e => e));
		var pauseRows = $('.dep-pause-row .dep-pause:visible');
		var workBegTime = this.getSecondsFromTimeStr($('.dep-worktime .begTime').val());
		var workEndTime = this.getSecondsFromTimeStr($('.dep-worktime .endTime').val());
		if (workBegTime == 0 && workEndTime == 0 && !$('.holiday').hasClass('tum-yellow fa-toggle-on')) {
			$('.dep-worktime .begTime').trigger('focus');
			return;
		}

		var isValidForm = true;
		$.each(pauseRows, function (i, el) {
			var timeBeg = self.getSecondsFromTimeStr($('.begTime', el).val());
			var timeEnd = self.getSecondsFromTimeStr($('.endTime', el).val());
			if ((timeBeg == 0 && timeEnd == 0) || +$(el).attr('data-valid') != 1) {
				isValidForm = false;
			}
		});

		if (!isValidForm) {
			return;
		}

		if ($('.dep-worktime input').hasClass('error')) {
			return;
		}

		var params = { model: model };
		
		ajaxSend({ url: this.options.ajaxURL.saveFirmDepartment, data: params }).then(data => {
			if (data.result) {
				showAlert("Успешно сохранено", { type: 'success' });
				$('#schedule').modal('hide');
				this.getSchedule($(`.collapse-cont[data-depid=${depId}]`));
			}
		});
	},
	showAbsentContactIcon: function ({agentPhone, agentEmail, agentPhoneAdditional}) {
		var str = '';
		if (!agentPhoneAdditional) {
			str += '<span data-toggle="popover" data-trigger="hover" data-content="Телефон для дозвонов" title=""><i class="fas fa-phone tum-dark-red mr-2"></i></span>';
		}

		if (!agentPhone) {
			str += '<span data-toggle="popover" data-trigger="hover" data-content="Телефон для SMS" title=""><i class="fas fa-sms tum-dark-red mr-2"></i></span>';
		}

		if (!agentEmail) {
			str += '<span data-toggle="popover" data-trigger="hover" data-content="Email" title=""><i class="fas fa-at tum-dark-red mr-2"></i></span>';
		}

		return str;
	},
	addInterval: function (el, type, item) {
		var tmpl = $.templates('#intervalConTemplate');
		var htmlOut = tmpl.render({ type: type, items: item });
		$('.department-container', el).append(htmlOut);
		dep.initTime();
	},
	removeInterval: function (el) {
		$(el).hide('fast', function () { $(el).remove(); });
	}
}

export default dep;